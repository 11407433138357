import React, { Component } from "react"
import { Link } from "gatsby"
import Navbar from "react-bootstrap/Navbar"
import { Waypoint } from "react-waypoint"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

class Navigation extends Component {
  state = {
    unlockNavbar: true,
  }

  handleScroll = () => {
    this.setState((prevState) => ({ unlockNavbar: !prevState.unlockNavbar }))
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            logo: file(relativePath: { eq: "logo.png" }) {
              childImageSharp {
                gatsbyImageData(width: 300, layout: CONSTRAINED)
              }
            }
          }
        `}
        render={(data) => (
          <Waypoint onLeave={this.handleScroll} onEnter={this.handleScroll}>
            <nav id="navigation">
              <Navbar
                expand="md"
                variant="dark"
                fixed="top"
                className={this.state.unlockNavbar ? "navbar-fade" : ""}
              >
                <div className="container">
                  <Navbar.Brand style={{ width: "9.375rem" }} href="/">
                    <GatsbyImage
                      image={data.logo.childImageSharp.gatsbyImageData}
                      alt="Tactic Apps Logo"
                    />
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <ul className="navbar-nav ml-auto dark-ul pl-4">
                      <li className="nav-item">
                        <Link
                          to="/services/"
                          activeClassName="active"
                          partiallyActive={true}
                          className="nav-link text-uppercase"
                        >
                          <span>Services</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/approach/"
                          activeClassName="active"
                          className="nav-link text-uppercase"
                        >
                          <span>Approach</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/work/"
                          partiallyActive={true}
                          activeClassName="active"
                          className="nav-link text-uppercase"
                        >
                          <span>Work</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/about-us/"
                          activeClassName="active"
                          className="nav-link text-uppercase"
                        >
                          <span>Company</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/contact/"
                          activeClassName="active"
                          className="nav-link text-uppercase"
                        >
                          <span>Contact</span>
                        </Link>
                      </li>
                    </ul>
                  </Navbar.Collapse>
                </div>
              </Navbar>
            </nav>
          </Waypoint>
        )}
      />
    )
  }
}

export default Navigation
