import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import CookieConsent from "react-cookie-consent"

import PhoneIcon from "../../assets/icons/phone.svg"
import MailIcon from "../../assets/icons/mail.svg"
import PinIcon from "../../assets/icons/location-pin.svg"

const Footer = ({ siteTitle }) => (
  <footer id="main-footer" className="bg-dark pt-5 pb-3 text-white">
    <div className="container">
      <div className="row no-gutters justify-content-between position-relative m-auto">
        <div className="square-bg d-none d-lg-block" />
        <div className="square-bg-small d-lg-none" />
        <div className="col-12 d-lg-none mb-5">
          <h5 className="mb-4">Navigation</h5>
          <div className="row no-gutters">
            <div className="col-6">
              <div className="mb-1">
                <Link className="text-light" to="/services/">
                  Services
                </Link>
              </div>
              <div className="mb-1">
                <Link className="text-light" to="/approach/">
                  Approach
                </Link>
              </div>
              <div className="mb-1">
                <Link className="text-light" to="/work/">
                  Work
                </Link>
              </div>
            </div>
            <div className="col-6 pl-5">
              <div className="mb-1">
                <Link className="text-light" to="/about-us/">
                  Company
                </Link>
              </div>
              <div className="mb-1">
                <Link className="text-light" to="/contact/">
                  Contact
                </Link>
              </div>
              <div className="mb-1">
                <Link className="text-light" to="/privacy-policy/">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-5 col-lg-3 col-md-6 mb-4">
          <div className="mb-4 d-flex align-items-center text-uppercase">
            <StaticImage
              src="../../assets/images/icon.png"
              alt="Tactic Apps Logo"
              layout="fixed"
              width={18}
              height={18}
              className="mr-2"
            />
            <h5 className="mb-0 ">{siteTitle}</h5>
          </div>
          <p>
            We bring your vision to life by developing modern{" "}
            <span className="text-primary">web and mobile applications </span>
            focused on the end user experience.
          </p>
          <small className="d-none d-lg-block">
            &copy; All rights reserved. {new Date().getFullYear()}{" "}
            TacticApps.com
          </small>
        </div>

        <div className="col-7 col-sm-6 d-md-none pl-5">
          <h5 className="mb-4">Contact Us</h5>
          <div className="row no-gutters ">
            <div className="col-1 mb-2">
              <PhoneIcon
                style={{
                  width: "16px",
                  height: "16px",
                  verticalAlign: "-0.1em",
                }}
              />
            </div>
            <div className="col-11 pl-2">
              <p className="fw-l m-0">+1 346-268-7886</p>
            </div>
            <div className="col-1 mb-2">
              <MailIcon
                style={{
                  width: "16px",
                  height: "16px",
                  verticalAlign: "-0.1em",
                }}
              />
            </div>
            <div className="col-11 pl-2">
              <p className="fw-l m-0">info@tacticapps.com</p>
            </div>
            <div className="col-1 mb-2">
              <PinIcon
                style={{
                  width: "16px",
                  height: "16px",
                  verticalAlign: "-0.1em",
                }}
              />
            </div>
            <div className="col-11 pl-2">
              <p>
                Houston, TX
                <br />
                USA
              </p>
            </div>
          </div>
        </div>
        <div className="d-none d-lg-block col-lg-4 mb-5 px-lg-4 pl-md-5">
          <h5 className="mb-4">Navigation</h5>
          <div className="row no-gutters">
            <div className="col-6">
              <div className="mb-1">
                <Link className="text-light" to="/services/">
                  Services
                </Link>
              </div>
              <div className="mb-1">
                <Link className="text-light" to="/approach/">
                  Approach
                </Link>
              </div>
              <div className="mb-1">
                <Link className="text-light" to="/work/">
                  Work
                </Link>
              </div>
            </div>
            <div className="col-6">
              <div className="mb-1">
                <Link className="text-light" to="/about-us/">
                  Company
                </Link>
              </div>
              <div className="mb-1">
                <Link className="text-light" to="/contact/">
                  Contact
                </Link>
              </div>
              <div className="mb-1">
                <Link className="text-light" to="/privacy-policy/">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 d-none d-md-block px-lg-3 pl-md-5">
          <h5 className="mb-4">Contact Us</h5>
          <div className="row no-gutters ">
            <div className="col-1 mb-2">
              <PhoneIcon
                style={{
                  width: "16px",
                  height: "16px",
                  verticalAlign: "-0.1em",
                }}
              />
            </div>
            <div className="col-11 pl-2">
              <p className="fw-l m-0">+1 346-268-7886</p>
            </div>
            <div className="col-1 mb-2">
              <MailIcon
                style={{
                  width: "16px",
                  height: "16px",
                  verticalAlign: "-0.1em",
                }}
              />
            </div>
            <div className="col-11 pl-2">
              <p className="fw-l m-0">info@tacticapps.com</p>
            </div>
            <div className="col-1 mb-2">
              <PinIcon
                style={{
                  width: "16px",
                  height: "16px",
                  verticalAlign: "-0.1em",
                }}
              />
            </div>
            <div className="col-11 pl-2">
              <p>
                Houston, TX
                <br />
                77433, USA
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 d-lg-none text-center">
          <small>
            &copy; All rights reserved. {new Date().getFullYear()}{" "}
            TacticApps.com
          </small>
        </div>
      </div>
    </div>
    <CookieConsent
      containerClasses="align-items-center py-3 px-5"
      disableButtonStyles={true}
      buttonClasses="btn btn-warning my-3 mr-5 text-capitalize"
      contentClasses="fw-l"
    >
      We use cookies to enhance your experience on our site. When you proceed,
      we assume this implies your agreement. You can read more in our{" "}
      <Link to="/privacy-policy" className="text-primary">
        Privacy Policy
      </Link>
      .
    </CookieConsent>
  </footer>
)

export default Footer
